import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../../components/layout'
import Prefooter from '../../components/prefooter'
import SectionHeader from '../../components/section-header'
import PageHeader from '../../components/page-header'
import QuestionAccordion from '../../components/question-accordion'
import PageIntro from '../../components/page-intro'
import PageSteps from '../../components/page-steps'
import PageClient from '../../components/page-client'
import ColorSwitch from '../../components/waypoint-color-switch'
import Drift from '../../components/waypoint-drift'

import CompanyIcon from '../../components/Icons/Company'

function LaunchNewCompanyPage({ data }) {
  const [t, { language }] = useTranslation(['translations', 'seo'])
  const clients = data.allPrismicClients.edges.map(item => item.node)

  return (
    <React.Fragment>
      <Helmet
        title={t('LaunchNewCompanyPage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('LaunchNewCompanyPage.title', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('LaunchNewCompanyPage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('LaunchNewCompanyPage.title', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('LaunchNewCompanyPage.title', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-launch-company.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/services/launch-new-company/',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Launch a new company' },
        ]}
      >
        <html lang={language} className="s-page s-page--company" />
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--short">
          <PageHeader
            lede={t('NewCompanyService.heading')}
            subheading={t('NewCompanyService.subheading')}
            classes="t-section-heading--narrow"
          />
        </section>
        <section className="s-container">
          <div className="s-page__icon">
            <CompanyIcon />
          </div>
        </section>
        <ColorSwitch />
        <Drift />
        <div>
          <PageIntro>
            <h4>{t('NewCompanyService.description')}</h4>
          </PageIntro>
          <QuestionAccordion
            title={t('NewCompanyService.questionsHeading')}
            items={t('NewCompanyService.faqs', { returnObjects: true })}
          />
          <section className="s-section s-container grid grid--2col-1-1">
            <div>
              <img src="/images/launch-company-01.jpg" alt="" />
            </div>
            <div>
              <img src="/images/launch-company-02.jpg" alt="" />
            </div>
          </section>
          <PageSteps
            callToAction={t('NewCompanyService.projectWorkLink')}
            heading={t('NewCompanyService.projectWorkHeading')}
          >
            <ol>
              {t('NewCompanyService.projectWorkTasks', {
                returnObjects: true,
              }).map(task => (
                <li key={task.name}>{task.name}</li>
              ))}
            </ol>
          </PageSteps>
          <section className="s-container s-section s-page__clients">
            <SectionHeader
              lede={t('NewCompanyService.projectExamplesHeading')}
              classes="t-section-heading--less-bottom"
            />
            {clients.map(client => (
              <PageClient key={client.id} client={client} page="Company" />
            ))}
          </section>
        </div>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query companyQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicClients(
      sort: { fields: [data___weight], order: ASC }
      filter: {
        data: {
          page_specific: { elemMatch: { page_type: { eq: "New Company" } } }
        }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          data {
            name
            page_specific {
              page_type
              content {
                document {
                  ... on PrismicClientServiceExamples {
                    data {
                      description
                      project_name
                      images {
                        image {
                          url
                          thumbnails {
                            small_1x {
                              url
                            }
                            small_2x {
                              url
                            }
                            medium_1x {
                              url
                            }
                            medium_2x {
                              url
                            }
                            large_1x {
                              url
                            }
                            large_2x {
                              url
                            }
                          }
                        }
                      }
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default LaunchNewCompanyPage
